export function extractGroupByVariables(sql: string): string[] {
  try {
    // Convert to lowercase for easier matching but keep original case for variables
    const originalSQL = sql;
    sql = sql.toLowerCase();
    
    // Find the GROUP BY clause, now including VISUALIZE as a possible terminator
    const groupByMatch = sql.match(/\bgroup\s+by\b(.*?)(?:\border\s+by\b|\bvisualize\b|$)/s);
    
    if (!groupByMatch) {
      return [];
    }
    
    // Extract the text between GROUP BY and the next clause (or end)
    const groupByClause = groupByMatch[1].trim();
    
    // Split by commas and clean up each variable
    const variables = groupByClause.split(',').map(variable => variable.trim());
    
    // Remove any empty strings and clean up whitespace
    const cleanedVariables = variables
      .filter(v => v)
      .map(v => {
        // Handle "AS" aliases by taking the alias name
        const regex = /\bas\s([a-z]+)/; // Capturing group for 'bbc'
        const asMatch = v.toLowerCase().match(regex);
        if (asMatch) {
          // Find position of alias in original SQL to preserve case

          const aliasPos = originalSQL.toLowerCase().indexOf(asMatch[1], groupByMatch.index + asMatch.index);

          const ret = originalSQL.substring(aliasPos, aliasPos + asMatch[1].length);
          return ret;
        } else {
          const pos = originalSQL.toLowerCase().indexOf(v, groupByMatch.index);
          const ret = originalSQL.substring(pos, pos + v.length);
          return ret;
        }
      });
    
    return cleanedVariables;
  } catch {
    return [];
  }
}