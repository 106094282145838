import './challenges.css';
import ChallengeSolution from './challenge-solution';


const Challenge5 = (() => {



  return (
    <div className="challenge-inner-container">
      {/* TODO: The idea here is to correlate an error to an earlier log. For that, we need better errors */}
      <p>There are lots of errors in the system, but one of them is particularly common. Find the <strong>Error</strong> log with the most common <strong>message</strong>.</p>

      <ChallengeSolution solutionQuery="SELECT COUNT(*) as times, message FROM Logs 
WHERE level = 5
GROUP BY message
ORDER BY times DESC
LIMIT 1"/>

      <p>Let's investigate a few sessions with that error. Create a query that shows all logs from sessions that have the same sessionId as the most common error log.</p>
        <ChallengeSolution solutionQuery="SELECT message, session_id
FROM Logs 
WHERE session_id IN
(SELECT session_id FROM Logs WHERE message LIKE '%already-posting%') 
ORDER BY time DESC"/>

      <p>Do you see something in common?</p>

      <ChallengeSolution solutionQuery=""
solutionText="Did you notice the same user is causing the issue every time? Someone called 'Nailuc Tsew'. Wait, that's our nemesis Culian West spelled backwards.
 Culian doesn't believe in the concept of analytics. He thinks engineers should manually go over hundreds of session logs. 
 Let's see if there's indeed a correlation between those errors and that user name."/>
 <p>Prove a correlation between the error and the commonality with a LEFT JOIN (which will do a Left Outer Join).</p>
      <ChallengeSolution solutionQuery="SELECT count(*), R.message as user_info
FROM Logs AS L
LEFT JOIN Logs AS R
ON L.correlationId = R.correlationId
WHERE L.level = 5
AND L.message LIKE '%error:already-posting%'
AND R.event_id = 'usr2'
GROUP BY user_info"

solutionText={<p>It seems that Culian is executing a DoS attack to try and destroy us. Good job! Now that we know what he's doing, we can defend accordingly.</p>}
/>
 
 

      
    </div>
  );
});

export default Challenge5;
