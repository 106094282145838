import { forwardRef, useImperativeHandle, useState } from 'react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from "ag-charts-community";
import logger from '../../utils/logger';

interface BarchartProps {
    visible: boolean;
    stacked: boolean;
}

const Barchart = forwardRef((props: BarchartProps, ref) => {

    useImperativeHandle(ref, () => ({
        callUpdateResults: updateResults,
    }));

    const updateResults = (results) => {
        logger.trace('updateResults: barchart data:', "YpUV1");
        logger.trace(results, "MjfG1");
        if (results.length > 0) {
            const firstRow = results[0];
            const XColumn = Object.keys(firstRow).find(key => typeof firstRow[key] === 'string');
            const valueColumns = Object.keys(firstRow).filter(key => key !== XColumn && !isNaN(Number(firstRow[key])));

            const data = results.map((row) => {
                const values = {};
                valueColumns.forEach((valueCol) => {
                    values[valueCol] = parseFloat(row[valueCol]);
                });
                values[XColumn] = row[XColumn];
                return values;
            }).sort((a, b) => a.date - b.date);

            logger.trace('updateResults: barchart data:' + JSON.stringify(data), "ZxiH1");

            const series = valueColumns.map((valueCol) => ({
                type: 'bar',
                xKey: XColumn,
                yKey: valueCol,
                yName: valueCol,
                stacked: props.stacked,
            }));

            logger.trace('updateResults: barchart series:' + JSON.stringify(series), "qeWP1");

            setOptions({
                data: data,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                series: series as any,
            });
        } else {
            setOptions({}); // no results
        }
    }

    const [options, setOptions] = useState<AgChartOptions>({}
    );

    return <div
        className='results-view'
        style={{
            height: '100%',
            border: 'none',
            visibility: props.visible ? 'visible' : 'hidden',
            display: props.visible ? 'block' : 'none',
        }}
    ><AgCharts options={options} style={{ width: '100%', height: '100%', display: 'block' }} /></div>;
});


export default Barchart;

