import { useEffect, useRef, useState } from 'react'
import { FiTable } from "react-icons/fi";

import { FcLineChart, FcPieChart, FcBarChart, FcAreaChart, FcScatterPlot } from 'react-icons/fc'
import { MdOutlineStackedBarChart } from "react-icons/md";
import { VisualizationType } from '../query';


// Example visualization types (you might already have these in your code)
// export const VisualizationType = {
//   TABLE: 'table',
//   TIMECHART: 'timechart',
//   PIE: 'pie',
//   BAR: 'bar',
//   STACKED_BAR: 'stacked-bar',
//   AREA: 'area',
//   SCATTER: 'scatter',
// }

const getIcon = (type, isBig) => {
  const size = isBig ? 22 : 19
  const color = isBig ?  '': '#3F51B5'
  switch (type) {
    case VisualizationType.TABLE:
      return <FiTable fontSize={size} color={color} />
      case VisualizationType.TIMECHART:
        return <FcLineChart fontSize={20} />
      case VisualizationType.PIE:
        return <FcPieChart fontSize={20} />
      case VisualizationType.BAR:
        return <FcBarChart fontSize={20} />
      case VisualizationType.STACKED_BAR:
        return <MdOutlineStackedBarChart fontSize={20} />
      case VisualizationType.AREA:
        return <FcAreaChart fontSize={20} />
      case VisualizationType.SCATTER:
        return <FcScatterPlot fontSize={20} />
    default:
      return null
  }
}

const VisualizationDropdown = ({ vis, selectedValue, onChange, openAbove, isDisabled }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null);
  // Find the currently selected object
  const selectedOption = vis.find((v) => v.type === selectedValue)


  // Close the dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      // If the click is outside the dropdownRef element, close the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])


  // Toggle the dropdown open/close
  const handleToggle = () => {
    setIsOpen((prev) => !prev)
  }

  // Handle selecting an option
  const handleSelect = (type) => {
    onChange(type)
    setIsOpen(false)
  }

  const dropdownPosition = openAbove
    ? 'bottom-full mb-2'
    : 'mt-2'

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      {/* Dropdown button */}
      <button
        disabled={isDisabled}
        onClick={handleToggle}
        className="inline-flex items-center px-3 py-2 border border-[1px] border-gray-300 rounded-xl text-gray-700 hover:bg-gray-100 focus:outline-none opacity-80"
      >
        <div className={`text-gray-900 ${isDisabled ? 'opacity-30' : ''}`}>
          {selectedOption && getIcon(selectedOption.type, true)}
          <span>{selectedOption ? "" : 'Select...'}</span>
        </div>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div
          className={`absolute z-10 mt-2 w-56 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 ${dropdownPosition}`}
          role="menu"
          aria-orientation="vertical"
        >
          <div className="py-1" role="none">
            {vis.map((item) => (
              <button
                key={item.type}
                onClick={() => handleSelect(item.type)}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-gray-900"
                role="menuitem"
              >
                
                {getIcon(item.type, false)}
                <span className="ml-2">{item.label}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default VisualizationDropdown
