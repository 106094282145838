import * as React from "react"
import { useRef, useState, useImperativeHandle, forwardRef } from "react";

import MonacoEditor from "./monaco-editor"
import '../common.css';
import './query.css';
import Timechart from "./visualization/timechart";
import Table from "./visualization/table";
import Piechart from "./visualization/piechart";
import { User } from "firebase/auth";
import { isSandboxUser as checkIsSandboxUser } from "../utils/url-utils";
import logger from "../utils/logger";
import { ChTable } from "./schema-contracts";
import { createHeadersForApiCall } from "../utils/api-utils";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import { convertNumbersInData } from "./query-response-utils";
import AutoRefreshButton from "./auto-refresh";
import Scatterchart from "./visualization/scatter";
import VisualizationDropdown from "./visualization/visualization-dropdown";
import InspectSidebar from "./Inspect-sidebar";
import Barchart from "./visualization/barchart";

export enum VisualizationType {
  TABLE = 'table',
  TIMECHART = 'timechart',
  PIE = 'pie',
  BAR = 'bar',
  STACKED_BAR = 'stacked-bar',
  AREA = 'area',
  SCATTER = 'scatter',
}

type ViewState = "Normal" | "QueryMaximized" | "ResultsMaximized";

interface QueryProps {
  index: number;
  isActive: boolean;
  ctrlEnterClicked: () => void;
  user: User;
  chSchema: ChTable[];
  takenWidth: number;
}

export interface Vis {
  type: VisualizationType;
  label: string;
  intellisense: string;
}

export const vis: Vis[] = [
  { type: VisualizationType.TABLE, label: 'Table', intellisense: 'table' },
  { type: VisualizationType.TIMECHART, label: 'Time chart', intellisense: 'timechart' },
  { type: VisualizationType.AREA, label: 'Stacked area chart', intellisense: 'areachart' },
  { type: VisualizationType.BAR, label: 'Bar chart', intellisense: 'barchart' },
  { type: VisualizationType.STACKED_BAR, label: 'Stacked bar chart', intellisense: 'barchart_stacked' },
  { type: VisualizationType.PIE, label: 'Pie chart', intellisense: 'piechart' },
  { type: VisualizationType.SCATTER, label: 'Scatter plot', intellisense: 'scatterplot' },
]

// function getVis(type: VisualizationType): Vis {
//   return vis.find(v => v.type === type);
// }

let initialQueryRan = false;

const SPLITTER_HEIGHT = 1;
const STATUS_BAR_HEIGHT = 30;
const INSPECT_WIDTH = 300;
const Query = forwardRef((props: QueryProps, ref) => {

  const editorRef = useRef(null);
  const inspectRef = useRef(null);

  const visRefs = [
    { type: VisualizationType.TABLE, ref: useRef(null) },
    { type: VisualizationType.TIMECHART, ref: useRef(null) },
    { type: VisualizationType.PIE, ref: useRef(null) },
    { type: VisualizationType.BAR, ref: useRef(null) },
    { type: VisualizationType.STACKED_BAR, ref: useRef(null) },
    { type: VisualizationType.AREA, ref: useRef(null) },
    { type: VisualizationType.SCATTER, ref: useRef(null) },
  ];

  function getVisRef(type: VisualizationType) {
    return visRefs.find(v => v.type === type).ref;
  }

  const autoRefreshButtonRef = useRef(null);
  const [isRunDisabled, setIsRunDisabled] = useState(false);
  const [results, setResults] = useState([]);
  const [viewState, setViewState] = useState<ViewState>("Normal");
  const [lastQueryDuration, setLastQueryDuration] = useState(undefined);
  const [visualizationType, setVisualizationType] = useState(VisualizationType.TABLE);
  const [lastUserVisualizationChoice, setLastUserVisualizationChoice] = useState(VisualizationType.TABLE);
  const [inspectItem, setInspectIem] = useState<string>("");
  const autoRefreshInterval = useRef<NodeJS.Timer | null>(null)
  const lastQuery = useRef<string>('');


  useImperativeHandle(ref, () => ({
    callSetValue: (value) => editorRef.current.callSetValue(value),
    callRunQuery: onRunQuery.bind(this),
    callRunQueryIfActive: (setTable: boolean = false) => onRunQuery(false /* useLastQuery */, setTable && VisualizationType.TABLE),
    // callGetSelectedQuery: ,
    callGetFullValue: () => editorRef.current.callGetFullValue(),
    callGetValue: () => editorRef.current.callGetValue(),
    callSetCaretAtLastQuery: () => editorRef.current.callSetCaretAtLastQuery(),
    callReLayoutMonaco: () => reLayoutMonaco(),
  }));



  const onRunQuery = async (useLastQuery: boolean, overrideVisualizationType: VisualizationType = undefined) => {
    logger.info('onRunQuery tabIndex ' + props.index + ' tabActive: ' + props.isActive, "2BVGf");
    if (!props.isActive) {
      return;
    }

    if (isRunDisabled) {
      return;
    }

    const queryCodeInitial = useLastQuery ? lastQuery.current : editorRef.current.callGetValue();
    lastQuery.current = queryCodeInitial;
    // eslint-disable-next-line prefer-const
    let [queryCode, newVisualizationType] = processQueryCode(queryCodeInitial);

    if (!newVisualizationType) {
      newVisualizationType = lastUserVisualizationChoice;
      if (overrideVisualizationType) {
        newVisualizationType = overrideVisualizationType;
      }
    }

    setIsRunDisabled(true);
    logger.info('onRunQuery: query: ' + queryCode, "vxUx7");

    try {
      const headers = await createHeadersForApiCall(props.user, logger);
      logger.trace(`onRunQuery: uid: ${headers.uid}`, "6x8T2");
      const localhost = true; // Change during DEBUG
      const reallyLocalhost = localhost && window.location.hostname === 'localhost';
      const queryApiUrl = reallyLocalhost ? 'http://localhost:8000/api/query' : 'https://obics.io/api/query/';
      const responseRaw = await fetch(queryApiUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          "query": queryCode,
        })
      });
      const resp = await responseRaw.json();

      if (resp.error) {
        updateErrorResult(resp.error);
      } else {
        const data = resp.response.data;
        const metadata = resp.response.meta;
        convertNumbersInData(data, metadata);

        setLastQueryDuration(resp.duration);
        setVisualizationType(newVisualizationType);
        setResults(data);
        updateResults(newVisualizationType, data);
        autoRefreshButtonRef.current.callEnable();
        logger.info(JSON.stringify({ event: "Query finished", queryduration: resp.duration, visualization: newVisualizationType }), "2snQY");
      }
    } catch (error) {
      logger.error('Error in query POST request: ' + (error?.toString()), "6x8T7");
      updateErrorResult(error);
    } finally {
      setIsRunDisabled(false);
      if (viewState === "QueryMaximized") {
        onExpandResults();
      }
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function updateErrorResult(error: any) {
    const errorObj = {
      error: error,
    };
    const errorResponse = [errorObj];

    setVisualizationType(VisualizationType.TABLE);
    setResults(errorResponse);
    stopAutoRefresh();
    autoRefreshButtonRef.current.callDisable();
    updateResults(VisualizationType.TABLE, errorResponse);
  }

  function updateResults(newVisualizationType, newResults = undefined) {
    logger.info('updateResults called. visualization: ' + VisualizationType[newVisualizationType], "6x8T3");
    const visType = newVisualizationType ?? visualizationType;
    getVisRef(visType).current.callUpdateResults(newResults ?? results);
  }

  const handleVisualizationChange = (visType) => {
    logger.trace(`handleVisualizationChange. event=${visType}`, "6x8T4");
    setLastUserVisualizationChoice(visType);
    setVisualizationType(visType);
    updateResults(visType);
  };

  const [topHeight, setTopHeight] = useState(40); // Initial height as 40% for sql and 60% for results
  const sandboxUser = checkIsSandboxUser(props.user);
  const [isResizing, setIsResizing] = useState(false);
  const resizingRef = useRef(isResizing);

  // On startup run the query
  React.useEffect(() => {
    if (initialQueryRan) {
      return;
    }
    initialQueryRan = true;
    onRunQuery(false /* useLastQuery */, undefined).then(() => {
    });
  }, []);

  React.useEffect(() => {
    resizingRef.current = isResizing;// Keep the ref updated whenever isMouseDown changes
  }, [isResizing]);

  const handleSplitterMouseDown = () => {
    if (viewState !== "Normal") {
      return;
    }

    logger.trace('handleSplitterMouseDown, starting resize', "6x8T5");
    setIsResizing(true);
    document.body.style.cursor = 'row-resize';
  };

  const handleMouseMove = (e) => {
    if (resizingRef.current) {
      // Calculate new height as a percentage of the container
      const containerRect = document.querySelector('.editor-and-results').getBoundingClientRect();
      const relativeY = e.clientY - containerRect.top;
      const newTopHeight = (relativeY / (containerRect.height - SPLITTER_HEIGHT)) * 100;

      // Limit panel resizing within 10% and 90% of the container height
      if (newTopHeight >= 10 && newTopHeight <= 90) {
        setTopHeight(newTopHeight);
      }
    }
  };

  const handleMouseUp = () => {
    if (resizingRef.current) {
      logger.trace('handleMouseUp, stopping resize', "6x8T6");
      setIsResizing(false);
      document.body.style.cursor = 'default';
      reLayoutMonaco();
    }
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove.bind(this));
    document.addEventListener('mouseup', handleMouseUp.bind(this));
    return () => {
      document.removeEventListener('mousemove', handleMouseMove.bind(this));
      document.removeEventListener('mouseup', handleMouseUp.bind(this));
    };
  }, []);

  const handleClickOutsideInspect = (event) => {
    if (inspectRef.current && inspectRef.current.contains(event.target)) {
      return;
    }

    const tableRef = getVisRef(VisualizationType.TABLE).current;
    if (tableRef && tableRef.callCheckMouseInside(event)) {
      return;
    }

    setInspectIem(null);
  };

  React.useEffect(() => {
    if (inspectItem) {
      document.addEventListener("mousedown", handleClickOutsideInspect);
    } else {
      document.removeEventListener("mousedown", handleClickOutsideInspect);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideInspect);
    };
  }, [inspectItem]);

  function reLayoutMonaco(extraLayout = false) {
    editorRef.current.callLayout();
    setTimeout(() => {
      editorRef.current.callLayout();
      if (extraLayout) {
        setTimeout(() => {
          editorRef.current.callLayout();
        }, 200);
      }
    }, 100);
  }


  const onExpandQuery = () => {
    if (viewState === "Normal") {
      setViewState("QueryMaximized");
    } else if (viewState === "ResultsMaximized") {
      setViewState("Normal");
    } else {
      return;
    }
    reLayoutMonaco(true);
  }

  const onExpandResults = () => {
    if (viewState === "Normal") {
      setViewState("ResultsMaximized");
    } else if (viewState === "QueryMaximized") {
      setViewState("Normal");
    } else {
      return;
    }
    reLayoutMonaco(true);

  }

  function getFriendlyDuration(lastQueryDuration: number | undefined): string {
    if (!lastQueryDuration) {
      return '';
    }

    if (lastQueryDuration < 10000) { // under 10 seconds
      return lastQueryDuration + 'ms';
    } else if (lastQueryDuration < 60000) { // under 1 minute
      return (lastQueryDuration / 1000).toFixed(1) + 's';
    } else {
      const minutes = (lastQueryDuration / 60000).toFixed(0);
      const seconds = ((lastQueryDuration % 60000) / 1000).toFixed(0);
      return minutes + ' minutes, ' + seconds + ' seconds';
    }
  }

  const handleAutoRefreshIntervalChanged = (intervalValueInSeconds) => {
    // setRefreshInterval(intervalValueInSeconds);
    if (autoRefreshInterval.current) {
      clearInterval(autoRefreshInterval.current);
      autoRefreshInterval.current = null;
    }

    if (intervalValueInSeconds !== null) {
      autoRefreshInterval.current = setInterval(() => {
        onRunQuery(true /* useLastQuery */);
      }, intervalValueInSeconds * 1000);
    }
  };

  const stopAutoRefresh = () => {
    if (autoRefreshInterval.current) {
      clearInterval(autoRefreshInterval.current);
      autoRefreshInterval.current = null;
    }


    autoRefreshButtonRef.current.callStopAutoRefresh();
  };

  function handleCloseInspect(): void {
    setInspectIem(null);
  }

  function onInspectCell(cellValue: string, force: boolean): void {
    if (force || !!inspectItem) {
      setInspectIem(cellValue);
    }
  }

  return (
    <div className="query-container bg-white">
      <div className="editor-and-results flex-1">
        {/* monaco editor */}
        <div style={{
          height: viewState === "QueryMaximized" ? "100%" : topHeight.toString() + "%",
          display: viewState === "ResultsMaximized" ? "none" : "block",
          maxWidth: `calc(100vw - ${props.takenWidth}px${inspectItem ? (` - ${INSPECT_WIDTH}px`) : ''})`
        }}>
          <MonacoEditor ref={editorRef} index={props.index} isSandBox={sandboxUser} chSchema={props.chSchema} ctrlEnterClicked={() => {
            props.ctrlEnterClicked();
          }}></MonacoEditor>
        </div>


        {/* splitter */}
        <div className={"py-[2px]" + (viewState === "Normal" && " cursor-row-resize")} onMouseDown={handleSplitterMouseDown} style={{ height: SPLITTER_HEIGHT }}>
          <div className="splitter-grip h-[1px] bg-[#DDDBDA]"></div>
        </div>

        {/* Bottom panel (navbar + results) */}
        <div className="flex"
          style={{
            height: viewState === "QueryMaximized" ? "60px" : (viewState === "ResultsMaximized" ? "100%" : (100 - topHeight).toString() + "%"),
            alignItems: 'stretch',
            flexDirection: 'column'
          }}>

          {/* control navbar*/}
          <div className="flex flex-row items-center gap-[16px] h-[60px] px-[10px] justify-between">
            <div id="left-part-of-control-navbar" className="flex flex-row items-center gap-[10px]">
              <div id="run-button-container" >
                <button disabled={isRunDisabled}
                  className="action-button-small w-[77.5px]"
                  style={{ cursor: isRunDisabled ? 'default' : 'pointer' }}
                  onClick={() => onRunQuery(false /* useLastQuery */, undefined)}
                  title="Run query (Ctrl + Enter)"
                >
                  {isRunDisabled ? <div className="spinner mt-[6px] " ></div> : <span>Run</span>}
                </button>
              </div>
              <span className="flex items-center ">
                <span className="mr-2 text-[16px]">Display as:</span>
                <VisualizationDropdown
                  vis={vis}
                  isDisabled={results.length === 0}
                  selectedValue={visualizationType}
                  onChange={handleVisualizationChange}
                  openAbove={viewState === "QueryMaximized" ? true : false}
                />
              </span>
              {/* <a href="#" className="text-[16px] text-regular text-gray-500" title="Coming soon">Chart Options...</a> */}
              <AutoRefreshButton ref={autoRefreshButtonRef} onIntervalSelected={handleAutoRefreshIntervalChanged} />
            </div>
            <div className="flex gap-1 items-center">
              <FaChevronDown className={"inline-block mb-[2px] w-[25px] h-[25px] p-1 hover:bg-gray-200 cursor-pointer" + (viewState === "QueryMaximized" ? " text-gray-200 cursor-default" : "")}
                onClick={onExpandQuery} />
              <FaChevronUp className={"inline-block w-[25px] h-[25px] p-1 hover:bg-gray-200 cursor-pointer" + (viewState === "ResultsMaximized" ? " text-gray-200 cursor-default" : "")}
                onClick={onExpandResults} />
            </div>
          </div>

          {/* table/charts */}
          <Table ref={getVisRef(VisualizationType.TABLE)} visible={visualizationType === VisualizationType.TABLE && viewState !== "QueryMaximized"} onInspectCell={onInspectCell} />
          <Timechart ref={getVisRef(VisualizationType.TIMECHART)} visible={visualizationType === VisualizationType.TIMECHART && viewState !== "QueryMaximized"} type="Line" query={lastQuery.current} />
          <Timechart ref={getVisRef(VisualizationType.AREA)} visible={visualizationType === VisualizationType.AREA && viewState !== "QueryMaximized"} type="StackedArea" query={lastQuery.current}/>
          <Piechart ref={getVisRef(VisualizationType.PIE)} visible={visualizationType === VisualizationType.PIE && viewState !== "QueryMaximized"} />
          {/* <Timechart ref={getVisRef(VisualizationType.BAR)} visible={visualizationType === VisualizationType.BAR && viewState !== "QueryMaximized"} type="Bar" query={lastQuery.current}/> */}
          {/* <Timechart ref={getVisRef(VisualizationType.STACKED_BAR)} visible={visualizationType === VisualizationType.STACKED_BAR && viewState !== "QueryMaximized"} type="StackedBar" query={lastQuery.current}/> */}
          <Barchart ref={getVisRef(VisualizationType.BAR)} visible={visualizationType === VisualizationType.BAR && viewState !== "QueryMaximized"} stacked={false} />
          <Barchart ref={getVisRef(VisualizationType.STACKED_BAR)} visible={visualizationType === VisualizationType.STACKED_BAR && viewState !== "QueryMaximized"} stacked={true} />
          <Scatterchart ref={getVisRef(VisualizationType.SCATTER)} visible={visualizationType === VisualizationType.SCATTER && viewState !== "QueryMaximized"} query={lastQuery.current} />
        </div>

        {/* status bar */}
        {lastQueryDuration &&
          <div className={`border border-t-[1px] border-b-0 border-r-0 border-l-0 border-gray-200 h-[${STATUS_BAR_HEIGHT}px] text-sm text-gray-700 bg-gray-100 flex items-center px-2`}>
            <span className="mt-1">Query time {getFriendlyDuration(lastQueryDuration)}. {results.length} rows{results.length === 10000 ? " (reached Maximum results limit)" : ""}.</span>
          </div>
        }

      </div>

      {/* inspect sidebar */}
      <div ref={inspectRef} className=" shadow-left h-full border border-l-[#E8E8E8] bg-white flex flex-col z-[100]" style={{ width: INSPECT_WIDTH, display: inspectItem ? 'flex' : 'none' }}>
        <InspectSidebar value={inspectItem} handleCloseInspect={handleCloseInspect} />
      </div>
    </div>

  )
});

export default Query




function processQueryCode(queryCodeInitial: string): [queryCode: string, newVisualizationType: VisualizationType] {
  let queryCode = queryCodeInitial.trim();

  if (queryCode[queryCode.length - 1] === ';') {
    queryCode = queryCode.slice(0, queryCode.length - 1);
  }


  const words = queryCode.split(/\s+/).filter(word => word.length > 0);
  if (words.length <= 1) {
    return [queryCode, undefined /* original VisualizationType */];
  }


  const lastWord = words[words.length - 1].toLowerCase();
  const secondToLastWord = words[words.length - 2].toLowerCase();
  if (secondToLastWord === 'visualize') {
    const wordsExceptLastTwo = words.slice(0, words.length - 2);
    const queryCodeWithoutChart = wordsExceptLastTwo.join(' ');

    const visualization = vis.find(v => v.intellisense.toLowerCase() === lastWord);
    if (visualization) {
      return [queryCodeWithoutChart, visualization.type];
    }
  }
  return [queryCode, undefined /* original VisualizationType */];
}

