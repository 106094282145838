import './challenges.css';
import ChallengeSolution from './challenge-solution';


const Challenge2 = (() => {



  return (
    <div className="challenge-inner-container">
      <p>In a real-time analytics database, giving each log statement a unique ID is very beneficial (though not mandatory).
        Log IDs makes it unnecessary to rely on error-prone methods like <strong>log patterns</strong>. 
        For example, for this sandbox, our app that has the following log:</p>
      <pre><code>{`logger.info('apag',\nJSON.stringify({"event": "Analytics.pageView",
  "url": req.originalUrl,
  "stats": local,
}));`}</code></pre>


      <p>For this log, the unique ID is <strong>apag</strong>. Try querying for 100 logs of this type by using the ID.</p>
      <ChallengeSolution solutionQuery="SELECT * FROM Logs WHERE event_id = 'apag' LIMIT 100"/>
      <p>This log shows the URL of each page visit. Extract the url from the log's message using <code>JSONExtractString</code>.</p>

      <ChallengeSolution solutionQuery={"SELECT JSONExtractString(message, 'url') as url\nFROM Logs\nWHERE event_id = 'apag'\nORDER BY timestamp Desc\nLIMIT 100"}/>

      <p><strong>Bonus: </strong>Find the 3 most common URLs.</p>
      <ChallengeSolution solutionQuery={`SELECT count(*) as count,
JSONExtractString(message, 'url') as url
FROM Logs
WHERE event_id = 'apag'
GROUP BY url
ORDER BY count DESC
LIMIT 3`}/>

    </div>
  );
});

export default Challenge2;
