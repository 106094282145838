import { forwardRef, useImperativeHandle, useState } from 'react';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { AgCharts } from 'ag-charts-react';
import { AgChartOptions } from "ag-charts-community";
import logger from '../../utils/logger';
import { isNumber, isValidDate } from '../../utils/general-utils';
import { extractGroupByVariables } from './visualization-utils';

type ChartType ="Line" | "StackedArea";

interface TimechartProps {
    query: string,
    visible: boolean;
    type: ChartType;
}

const axes = [
    {
        type: 'time',
        position: 'bottom',
        label: {
            format: '%Y-%m-%d %H:%M',
        },
    },
    {
        type: 'number',
        position: 'left',
    },
];

const Timechart = forwardRef((props: TimechartProps, ref) => {

    useImperativeHandle(ref, () => ({
        callUpdateResults: updateResults,
    }));

    const updateResults = (results) => {
        logger.trace('updateResults: timechart data:', "YpUVU");
        logger.trace(results, "MjfG6");
        if (results.length == 0) {
            setOptions({});
            return;
        }

        const firstRow = results[0];
        const groupByColumnsHint = extractGroupByVariables(props.query);
        const XColumn = getTimeColumn(firstRow, groupByColumnsHint);// props.type === 'Line' ? getTimeColumn(firstRow, groupByColumnsHint) : getXColumn(firstRow, groupByColumnsHint);
        const valueColumn = getValueColumn(firstRow, XColumn, groupByColumnsHint);
        const groupByColumns = Object.keys(firstRow).filter(key => key !== XColumn && key!== valueColumn);
        if (groupByColumns.length > 0) { // multi lines
            handleMultiLine(groupByColumns, results, XColumn, valueColumn, setOptions);
        } else {
            handleSingleLine(results, valueColumn, XColumn, setOptions);
        }
    }

    const [options, setOptions] = useState<AgChartOptions>({}
    );

    function handleMultiLine(groupByColumns: string[], results: any, XColumn: string, valueColumnsKey: string, setOptions) {
        const groupByColumn = groupByColumns[0]; // support only one "group by" right now
        const groupByValues: string[] = results.map(row => row[groupByColumn].toString());
        const distinctGroupByValues = Array.from(new Set(groupByValues));
    
        //.filter((value, index, self) => self.indexOf(value) === index);
        if (distinctGroupByValues.length > 100) {
            distinctGroupByValues.length = 100;
        }
    
        const newData = [];
        const resultsByTime = results.sort((a, b) => a[XColumn] - b[XColumn]);
        let rowNumber = 0;
        let lastTime = null;
        let rowToInsert = {};
        while (resultsByTime.length > rowNumber) {
            const row = resultsByTime[rowNumber];
            const time = row[XColumn];
            if (lastTime === null || time != lastTime) {
                lastTime = time;
                if (rowToInsert) {
                    newData.push(rowToInsert);
                }
                rowToInsert = {};
                rowToInsert[XColumn] = new Date(time).getTime();
                distinctGroupByValues.forEach((valueCol) => {
                    rowToInsert[valueCol] = 0;
                });
            }
    
            const groupByValue = row[groupByColumn].toString();
            const groupByIndex = distinctGroupByValues.indexOf(groupByValue);
            if (groupByIndex >= 0) {
                rowToInsert[groupByValue] = row[valueColumnsKey];
            }
    
            rowNumber++;
        }
    
        logger.trace('updateResults: timechart data:' + JSON.stringify(newData), "ZxiH1");
    
        const series = distinctGroupByValues.map((valueCol) => ({
            // type: 'area',
            type: getSeriesType(props.type),
            xKey: XColumn,
            xName: "Date",
            yKey: valueCol,
            yName: valueCol == "" ? "[Empty]" : valueCol,
            stacked: props.type == "StackedArea",
        }));
    
        logger.trace('updateResults: timechart series:' + JSON.stringify(series), "qeWP2");
    
        setOptions({
            data: newData,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            series: series as any, //series1 as any,
    
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            axes: axes as any,
        });
    }

    return <div
        className='results-view'
        style={{
            height: '100%',
            border: 'none',
            visibility: props.visible ? 'visible' : 'hidden',
            display: props.visible ? 'block' : 'none',
        }}
    ><AgCharts options={options} style={{ width: '100%', height: '100%', display: 'block' }} /></div>;
});


export default Timechart;



function getSeriesType(type: ChartType) {
    switch (type) {
        case "Line":
            return "line";
        case "StackedArea":
            return "area";
        // case "Bar":
        //     return "bar";
        // case "StackedBar":
        //     return "bar";
        default:
            return "line";
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function handleSingleLine(results: Array<any>, valueColumnKey: string, timeColumn: string, setOptions) {
    const data = results.map((row) => {
        const values = {};
        values[valueColumnKey] = parseFloat(row[valueColumnKey]);
        return {
            date: new Date(row[timeColumn]),
            ...values,
        };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }).sort((a: any, b: any) => a.date - b.date);

    logger.trace('updateResults: timechart data:' + JSON.stringify(data), "ZxiHH");

    const series = [{
        type: 'line',
        xKey: 'date',
        yKey: valueColumnKey,
    }];

    logger.trace('updateResults: timechart series:' + JSON.stringify(series), "qeWPz");

    setOptions({
        data: data,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        series: series as any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        axes: axes as any,
    });
}



// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTimeColumn(firstRow: any, groupByColumnsHint: string[]) {
    const keys = Object.keys(firstRow);
    const best = keys.map((key, index) => {
        let score = 0;
        const lower = key.toLowerCase();
        const value = firstRow[key];
        if (isValidDate(value)) {
            score += 3;
        }
        if (groupByColumnsHint.includes(key)) {
            score += 1;
        }
        if (lower.includes('()')) {
            score -= 1;
        }
        if (lower.includes('time')) {
            score += 1;
        }
        if (lower.includes('date')) {
            score += 1;
        }
        if (lower.includes('day')) {
            score += 1;
        }
        if (lower.includes('month')) {
            score += 1;
        }
        if (lower.includes('year')) {
            score += 1;
        }
        return [index, score];
    }).sort((a, b) => b[1] - a[1])[0][0];
    return keys[best];

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getXColumn(firstRow: any, groupByColumnsHint: string[]) {
    const keys = Object.keys(firstRow);
    const best = keys.map((key, index) => {
        let score = 0;
        // const lower = key.toLowerCase();
        // const value = firstRow[key];
        if (groupByColumnsHint.includes(key)) {
            score += 1;
        }
        
        return [index, score];
    }).sort((a, b) => b[1] - a[1])[0][0];
    return keys[best];

}


export function getNumericColumns(firstRow: unknown) {
    return Object.keys(firstRow).filter(key => !isNaN(Number(firstRow[key])));
}


function getValueColumn(firstRow: unknown, timeColumn: string, groupByColumnsHint: string[]) {
    const keys = Object.keys(firstRow);
    const timeColumnLower = timeColumn.toLowerCase();
    const best = keys.map((key, index) => {
        let score = 0;
        const keyLower = key.toLowerCase();
        if (keyLower === timeColumnLower) {
            score -= 1000;
        }

        if (groupByColumnsHint.includes(key)) {
            score -= 100;
        }

        const value = firstRow[key];

        if (isNumber(value)) {
            score += 3;
        }
        if (keyLower.includes('times')
            || keyLower.includes('amount')
            || keyLower.includes('value')
            || keyLower.includes('count')
            || keyLower.includes('size')) {
            score += 1;
        }

        return [index, score];
    }).sort((a, b) => b[1] - a[1])[0][0];
    return keys[best];

}